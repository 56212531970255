import React from 'react';
import CopyDesktop from 'images/copy_desktop.svg';

export default class CopyWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textTooltip: 'Copy to clipboard',
      isShow: false,
    };
  }

  copyText = (e) => {
    e.stopPropagation();
    clearTimeout(this.timerShow);
    clearTimeout(this.timerText);
    const { id } = this.props;
    const text = document.getElementById(id).innerText;
    const elem = document.createElement('textarea');
    elem.style.cssText = 'display:block;width:0;height:0;opacity:0';
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    elem.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(elem);

    this.setState({ textTooltip: 'Copied', isShow: true });
    this.timerShow = setTimeout(() => {
      this.setState({ isShow: false });
    }, 2000);
    this.timerText = setTimeout(() => {
      this.setState({ textTooltip: 'Copy to clipboard' });
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerShow);
    clearTimeout(this.timerText);
  }

  render() {
    const { children } = this.props;
    const { textTooltip, isShow } = this.state;
    return (
      <>
        {children}
        <button
          type="button"
          className="copy_icon"
          aria-label="Copy"
        >
          <div onClick={this.copyText}>
            <img
              className="icon"
              src={CopyDesktop}
              alt="Copy"
            />
          </div>
          <div className={`copy_tooltip ${isShow ? 'show' : ''}`}>{textTooltip}</div>
        </button>
      </>
    );
  }
}
