import React from 'react';
import OurTeamImage from 'images/our_team_img.svg';

export default class OurTeam extends React.PureComponent {
  render() {
    return (
      <div className="our_team" id="team">
        <h2 className="category">Our Team</h2>
        <hr align="center" className="line" />
        <img
          className="our_team_image lazyload"
          data-src={OurTeamImage}
          alt="Our team"
        />
        <div className="wrap_content">
          <div className="our_team_content">
            <p className="description">
            Every member of our team is unique but we all
            work in close collaboration to form a single organism we call
            Cybergizer
            </p>
            <a href="/team" className="our_team_btn">meet the team</a>
          </div>
        </div>
      </div>
    );
  }
}
