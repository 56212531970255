import React from 'react';

export default class StaticData extends React.Component {
  numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (
      <div className="static_data">
        <div className="founded">
          <span className="founded-text">Founded in</span>
          <div className="founded-year">
            <span>2</span>
            <span>0</span>
            <span>1</span>
            <span>6</span>
          </div>
        </div>
        <div className="engineers">
          <div className="engineers-block">
            <span>Number<br/> of engineers</span>
            <span className="count-hours">{this.props.dynamic_datum.employer_count}</span>
          </div>
          <div className="projects-block">
            <span className="count-hours">{this.props.dynamic_datum.project_count}</span>
            <span>Projects<br/> delivered</span>
          </div>
        </div>
        <div className="hours">
          <span className="hours-text">Hours dedicated<br/> to customers<br/> and growth</span>
          <span className="count-hours">{this.numberWithCommas(this.props.dynamic_datum.work_hours)}</span>
        </div>
        <div className="offices">
          <span className="offices-text">Office in</span>
          <div className="offices-country">
              <span>VLN</span>
          </div>
        </div>
      </div>
    );
  }
}
