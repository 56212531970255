import React from 'react';
import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import Title from 'images/title.svg';
import MobileTitle from 'images/mobile_title.svg';
import { EasePack } from 'gsap/EasePack';

const Field = '00000000000000000000000000000000000000000000000000001000000000000000000'
+ '00000000000000000000000000000000000000000000000000000000000000000000000000000000000'
+ '00000000000000';

export default class AnimatedTitle extends React.Component {
  constructor(props) {
    super(props);
    this.mouseDown = false;
    this.isActiveRedButton = false;
    this.cyberTitleImg = React.createRef();
    this.cursor = React.createRef();
    this.gridRef = React.createRef();
    this.animationUniteAndSetBall = gsap.timeline({ paused: true });
  }

  componentDidMount() {
    if (this.gridRef.current.clientWidth > 0) {
      gsap.registerPlugin(CSSPlugin);
      gsap.registerPlugin(EasePack);
      gsap.config({
        autoSleep: 60,
        force3D: false,
      });
      const path = '.ball>svg';
      const pathBall = '.ball>svg>path';
      const pathLine = '.ball>svg>line';
      this.elementSvg = gsap.utils.toArray(path);
      this.elementSvgPath = gsap.utils.toArray(pathBall);
      this.elementSvgLine = gsap.utils.toArray(pathLine);

      this.firstAnimatedBall(3);
    }
  }

  firstAnimatedBall = (duration = 2.5) => {
    const firstAnimateSvg = gsap.timeline({ autoRemoveChildren: true });
    const stagger_val_ball = {
      grid: [56, 168],
      from: 'center',
      amount: -duration * 0.6,
    };
    const stagger_val = {
      grid: [7, 24],
      from: 'center',
      amount: -duration * 0.6,
    };


    firstAnimateSvg.set(this.elementSvgPath, {
      x: 'random(-2000, 2000)',
      y: 'random(-500, 500)',
      rotation: 'random(-720, 720)',
      opacity: 0,
    }, 0);

    const nextStep = () => {
      firstAnimateSvg.set(this.elementSvg, {
        top: index => `${Math.floor(index / 24) * 1.4}em`,
        left: index => `${(index % 24) * 1.4}em`,
        opacity: 1,
      });
      firstAnimateSvg.set(this.elementSvgPath, {
        x: 'random(-20, 20)',
        y: 'random(-20, 20)',
        rotation: 'random(-360, 360)',
      }, 'nextStart');
    };


    const stagger_from_prev = {
      top: '50%',
      left: '50%',
      duration: duration * 0.2,
      stagger: stagger_val,
    };
    const stagger_to_next = {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      rotation: 0,
      ease: 'slow',
      duration: duration * 0.1,
      stagger: stagger_val_ball,
    };
    const stagger_to = {
      duration: duration * 0.32,
      stagger: { amount: duration * 0.4 },
      onComplete: nextStep,
      x: 0,
      y: 0,
      opacity: 1,
      scale: 0,
      rotation: 0,
      ease: 'circ.out',
    };
    firstAnimateSvg.addLabel('start')
      .set(this.elementSvg, { opacity: 1 }, 'start')
      .set(this.elementSvgLine, { opacity: 0 }, 'start')
      .set(this.elementSvgPath, {
        opacity: 1,
        stagger: {
          amount: duration * 0.4,
        },
      }, 'start')
      .to(this.elementSvgPath, stagger_to, 'start')
      .addLabel('nextStart')
      .from(this.elementSvg, stagger_from_prev, 'nextStart')
      .to(this.elementSvgPath, stagger_to_next, 'nextStart')
      .to(this.elementSvgLine, {
        duration: duration * 0.1,
        opacity: 1,
        stagger: stagger_val_ball,
        onComplete: this.animationUniteAndSet,
      }, `nextStart+=${duration * 0.1}`);
    this.animationActive = true;
  }

  animationUniteAndSet = () => {
    const duration = 2;
    const stagger_val_path = {
      grid: [56, 168],
      from: 364,
      amount: duration * 0.6,
    };
    const stagger_val = {
      grid: [7, 24],
      from: 52,
      amount: duration * 0.6,
    };

    const stagger_to = {
      duration: duration * 0.4,
      top: '2.8em',
      left: '5.6em',
      scale: 0.5,
      ease: 'circ.in',
      display: 'none',
      stagger: stagger_val,
    };
    const stagger_to_path = {
      duration: duration * 0.4,
      stagger: stagger_val_path,
      x: 0,
      y: 0,
      rotationY: 0,
      ease: 'power3.out',
    };
    const timeline = gsap.timeline({ paused: true })
      .set(this.elementSvgLine, {
        opacity: 0,
        stagger: stagger_val_path,
      }, 0)
      .to(this.elementSvg, stagger_to, 0)
      .set(this.elementSvgPath, { x: 0, y: 0, rotationY: 0 }, 0)
      .to(this.elementSvgPath, {
        duration: duration * 0.2,
        x: 'random(-100, 100)',
        y: 'random(-100, 100)',
        rotationY: 'random(-360, 360)',
        stagger: stagger_val_path,
      }, 0)
      .to(this.elementSvgPath, stagger_to_path, duration * 0.2)
      .to(this.cyberTitleImg.current, 1, {
        opacity: 1,
        ease: 'circ.in',
      }, `-=${duration * 0.4}`);
    this.animationUniteAndSetBall = timeline;
  };

  handleOnMouse = (event) => {
    if (this.animationActive) {
      const { currentTarget } = event;
      if (!gsap.isTweening(currentTarget.firstChild.firstChild)) {
        if (this.mouseDown) this.handleClick(event);
        else {
          gsap.fromTo(currentTarget.firstChild,
            { rotationY: 0 },
            { rotationY: 360, duration: 1 });
        }
      }
    }
  }

  handleClick = ({ currentTarget }) => {
    if (this.animationActive) {
      const duration = 2;
      const durationSplit = duration * 0.5;
      const durationJoin = duration * 0.5;
      const svgChilds = Array.from(currentTarget.firstChild.children);
      const elemSplitPath = svgChilds.filter(el => el.nodeName === 'path');
      const elemSplitline = svgChilds.filter(el => el.nodeName === 'line');
      if (gsap.isTweening(svgChilds)) {
        gsap.killTweensOf(svgChilds);
      }
      gsap.timeline({ autoRemoveChildren: true })
        .addLabel('click')
        .set(currentTarget.firstChild, { rotationY: 0, zIndex: 51 }, 'click')
        .set(elemSplitline, { opacity: 0 }, 'click')
        .set(elemSplitline, { clearProps: 'all' }, `click+=${duration}`)
        .set(currentTarget.firstChild, { rotationY: 0, zIndex: 1 }, `click+=${duration}`)
        .to(elemSplitPath, {
          duration: durationSplit,
          x: 'random(-60, 60)',
          y: 'random(-60, 60)',
          rotation: 'random(-90, 90)',
          fill: '#000',
        }, 'click')
        .to(elemSplitPath, {
          duration: durationJoin,
          x: 0,
          y: 0,
          rotation: 0,
          fill: '#333333',
          opacity: 1,
        }, `click+=${durationSplit}`);
    }
  }

  handleRedClick = () => {
    if (!this.isActiveRedButton) {
      gsap.globalTimeline.pause('-=0').resume();
      this.animationUniteAndSetBall.play(0).invalidate();
      this.isActiveRedButton = true;
    } else {
      this.animationUniteAndSetBall.reverse();
      this.isActiveRedButton = false;
    }
  }

  cursorBig = (event) => {
    const el = this.cursor.current;
    el.style.cssText = `opacity: 1;
        top:${event.clientY}px;
        left:${event.clientX}px;
        transform: scale(3) translateX(0) translateY(30%);`;
  }

  cursorSmall = () => {
    const el = this.cursor.current;
    el.style.cssText = 'opacity: 0;transform: scale(1);';
  }

  scaleAnimation = (scale = 1) => {
    if (this.animationUniteAndSetBall.isActive()) {
      gsap.globalTimeline.timeScale(scale);
    }
  }

  mouseDownAndUp = (event) => {
    if (event.type === 'mousedown') {
      this.mouseDown = true;
      this.scaleAnimation(0.15);
    } else {
      this.mouseDown = false;
      this.scaleAnimation(1);
    }
  }

  addBall(type, index) {
    if (type === '0') {
      return (
        <div
          onMouseEnter={this.handleOnMouse}
          onMouseDown={this.handleClick}
          className="ball"
          key={`ball_key_${index}`}
        >
          <svg
            x="0"
            y="0"
            viewBox="0 0 70 70"
            overflow="visible"
            visibility={type === '0' ? 'visible' : 'hidden'}
          >
            <path d="M 35 35
                    L 17.5 65.310889133
                    A 35 35, 0, 0, 0, 52.5 65.310889133
                    Z"
            />
            <path d="M 35 35
                    L 0.430908079 40.475206275
                    A 35 35, 0, 0, 0, 17.5 65.310889133
                    Z"
            />
            <path d="M 52.5 32.228272294
                    L 0.430908079 40.475206275
                    A 35 35, 0, 0, 1, 2.110758272 23.029294984
                    Z"
            />
            <path d="M 52.5 32.228272294
                    L 52.5 65.310889133
                    L 35 35
                    Z"
            />
            <path d="M 52.5 32.228272294
                    L 52.5 65.310889133
                    A 35 35, 0, 0, 0, 69.99744887778982 35.422577858
                    Z"
            />
            <path d="M 30 28.120709204
                  L 2.110758272 23.029294984
                  A 35 35, 0, 0, 1, 52.5 4.689110868
                  Z"
            />
            <path d="M 30 28.120709204
                  L 52.5 4.689110868
                  A 35 35, 0, 0, 1, 69.99744887778982 35.422577858
                  Z"
            />
            <line x1="35" y1="35" x2="17.5" y2="65.310889133" />
            <line x1="35" y1="35" x2="0.430908079" y2="40.475206275" />
            <line x1="52.5" y1="32.228272294" x2="0.430908079" y2="40.475206275" />
            <line x1="52.5" y1="32.228272294" x2="52.5" y2="65.310889133" />
            <line x1="35" y1="35" x2="52.5" y2="65.310889133" />
            <line
              x1="2.110758272"
              y1="23.029294984"
              x2="69.99744887778982"
              y2="35.422577858"
            />
            <line x1="30" y1="28.120709204" x2="52.5" y2="4.689110868" />
          </svg>
        </div>

      );
    }
    return (
      <div
        onMouseEnter={this.cursorBig}
        onMouseMove={this.cursorBig}
        onMouseLeave={this.cursorSmall}
        onClick={this.handleRedClick}
        className="ball button"
        key={`ball_key_${index}`}
      >
        <svg
          x="0"
          y="0"
          viewBox="0 0 70 70"
          overflow="visible"
          visibility={type === '0' ? 'visible' : 'hidden'}
        >
          <path d="M 35 35
                    L 17.5 65.310889133
                    A 35 35, 0, 0, 0, 52.5 65.310889133
                    Z"
          />
          <path d="M 35 35
                    L 0.430908079 40.475206275
                    A 35 35, 0, 0, 0, 17.5 65.310889133
                    Z"
          />
          <path d="M 52.5 32.228272294
                    L 0.430908079 40.475206275
                    A 35 35, 0, 0, 1, 2.110758272 23.029294984
                    Z"
          />
          <path d="M 52.5 32.228272294
                    L 52.5 65.310889133
                    L 35 35
                    Z"
          />
          <path d="M 52.5 32.228272294
                    L 52.5 65.310889133
                    A 35 35, 0, 0, 0, 69.99744887778982 35.422577858
                    Z"
          />
          <path d="M 30 28.120709204
                  L 2.110758272 23.029294984
                  A 35 35, 0, 0, 1, 52.5 4.689110868
                  Z"
          />
          <path d="M 30 28.120709204
                  L 52.5 4.689110868
                  A 35 35, 0, 0, 1, 69.99744887778982 35.422577858
                  Z"
          />
          <line x1="35" y1="35" x2="17.5" y2="65.310889133" />
          <line x1="35" y1="35" x2="0.430908079" y2="40.475206275" />
          <line x1="52.5" y1="32.228272294" x2="0.430908079" y2="40.475206275" />
          <line x1="52.5" y1="32.228272294" x2="52.5" y2="65.310889133" />
          <line x1="35" y1="35" x2="52.5" y2="65.310889133" />
          <line
            x1="2.110758272"
            y1="23.029294984"
            x2="69.99744887778982"
            y2="35.422577858"
          />
          <line x1="30" y1="28.120709204" x2="52.5" y2="4.689110868" />
        </svg>
      </div>
    );
  }

  renderBalls = () => {
    const arrayOfBalls = Array.from({ length: Field.length },
      (_, index) => this.addBall(Field[index], index));
    return (
      <div className="balls_section">
        <div
          className="grid"
          onMouseDown={this.mouseDownAndUp}
          onMouseLeave={this.mouseDownAndUp}
          onMouseUp={this.mouseDownAndUp}
          ref={this.gridRef}
        >
          {arrayOfBalls}
        </div>
        <img
          className="cybergizer_title"
          src={Title}
          alt="title"
          ref={this.cyberTitleImg}
        />
        <img
          className="cybergizer_title_mobile"
          src={MobileTitle}
          alt="title"
        />
        <div className="cursor" ref={this.cursor} />
      </div>
    );
  }

  render() {
    return this.renderBalls();
  }
}
