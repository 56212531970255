import React from 'react';
import LittleLogo from 'images/icons/little-logo.svg';
import CopyWrap from './CopyWrap';

export default class FooterCards extends React.PureComponent {
  render() {
    return (
      <>
        <div className="footer_cards">
          <img
            src={LittleLogo}
            alt="little-logo"
            className="little-logo"
          />
          <div className="card">
            <p className="title">EMAIL</p>
            <div className="email">
              <CopyWrap id="email_footer_cards">
                <a
                  id="email_footer_cards"
                  className="email_footer text"
                  href="mailto:info@cybergizer.com"
                >
                  info@cybergizer.com
                </a>
              </CopyWrap>
            </div>
            <div className="top-right-corner-1" />
            <div className="bottom-left-line-1" />
          </div>
          <div className="card address">
            <p className="title">ADDRESS</p>
            <p className="text">
              Kaštonų g. 27 K1, Pagiriai,
              <br />
              VLT-14117 Vilniaus r. Lithuania
            </p>
            <div className="top-left-corner-2" />
            <div className="top-right-line-2" />
            <div className="right-line-2" />
            <div className="bottom-line-2" />
          </div>
          <div className="card">
            <p className="title">STAY IN TOUCH</p>
            <div className="bottom-corner-3" />
            <div className="top-line-3" />
            <div className="icon-container">
              <a
                href="https://www.facebook.com/cybergizer/"
                target="_blank"
                className="link facebook"
                aria-label="Cybergizer Facebook link"
                rel="noopener noreferrer"
              />
              <a
                href="https://www.linkedin.com/company/cybergizer-llc/"
                target="_blank"
                className="link linkedin"
                aria-label="Cybergizer Linkedin link"
                rel="noopener noreferrer"
              />
              <a
                href="https://www.instagram.com/cybergizer/"
                target="_blank"
                className="link instagram"
                aria-label="Cybergizer Instagram link"
                rel="noopener noreferrer"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
