import React from 'react';
import ContactForm from '../../layouts/ContactForm';
import FormFooter from '../../layouts/FormFooter';

export default class MainRightForm extends React.Component {
  componentDidMount = () => {
    const element = document.querySelector('.main_right_form');
    element.classList.remove('closed');
  }

  handleClick = () => {
    const element = document.querySelector('.main_right_form');
    element.classList.toggle('open');
  }

  closeForm = () => {
    const element = document.querySelector('.main_right_form');
    element.classList.remove('open');
  }

  render() {
    const { csrf_token } = this.props;
    return (
      <div className="main_right_form closed">
        <button
          type="button"
          className="talk_btn"
          onClick={this.handleClick}
        >
                Talk to us
        </button>
        <ContactForm
          csrf_token={csrf_token}
          type="main_right_form"
          handleClose={this.closeForm}
        />
        <FormFooter />
      </div>
    );
  }
}
