import React from 'react';
import Cross from 'images/icons/cross.svg';
import Ruby from 'images/icons/ruby.svg';
import RubyMobile from 'images/icons/ruby_mobile.svg';
import Elixir from 'images/icons/elixir.svg';
import ElixirMobile from 'images/icons/elixir_mobile.svg';
import WebDesign from 'images/icons/web_design.svg';
import WebDesignMobile from 'images/icons/web_design_mobile.svg';
import ReactIcon from 'images/icons/react.svg';
import ReactIconMobile from 'images/icons/react_mobile.svg';
import DevOps from 'images/icons/devops.svg';
import DevOpsMobile from 'images/icons/devops_mobile.svg';
import Mobile from 'images/icons/mobile.svg';
import MobileMobile from 'images/icons/mobile_mobile.svg';
import QA from 'images/icons/qa.svg';
import QAMobile from 'images/icons/qa_mobile.svg';

export default class TechnologyCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popUp: false };
  }

  showPopUp = (popUp) => {
    this.setState({ popUp });
  }

  renderMobilePopUp() {
    const { popUp } = this.state;
    const { text, type, style } = popUp;
    return (
      <div className="popup-container">
        <div className="mobile-popup">
          <button className="cross" type="button" onClick={() => this.showPopUp(false)}>
            <img
              alt="cross"
              src={Cross}
            />
          </button>
          <img
            alt={type}
            src={`/assets/icons/${type}.svg`}
            style={style}
          />
          <p className="first">
            While we are working on our
            <br />
            awesome new website,
            <br />
            you still can get more details
            <br />
            about our&nbsp;
            {text}
            projects portfolio or customer
            <br />
            references.
          </p>
          <p>
            Just drop us a line at
            <br />
            <a href="mailto:info@cybergizer.com">info@cybergizer.com</a>
            <br />
            and we will share more info.
          </p>
          <button type="button" onClick={() => this.showPopUp(false)}>Got it!</button>
        </div>
      </div>
    );
  }

  renderPopUp() {
    if (window && window.screen.width < 1024) {
      return this.renderMobilePopUp();
    }
    const { popUp } = this.state;
    const { text, type, style } = popUp;
    return (
      <div className="popup-container">
        <div className="popup">
          <button className="cross" type="button" onClick={() => this.showPopUp(false)}>
            <img
              className="cross"
              alt="cross"
              src={Cross}
            />
          </button>
          <img
            alt={type}
            src={`/assets/icons/${type}.svg`}
            style={style}
          />
          <p className="first">
            While we are working on our awesome new website,
            <br />
            you still can get more details about our&nbsp;
            {text}
            projects portfolio or customer references.
          </p>
          <p>
            Just drop us a line at&nbsp;
            <a href="mailto:info@cybergizer.com">info@cybergizer.com</a>
            <br />
            and we will share more info.
          </p>
          <button type="button" onClick={() => this.showPopUp(false)}>Got it!</button>
        </div>
      </div>
    );
  }

  render() {
    const { popUp } = this.state;
    return (
      <div className="technology_stack">
        {popUp && this.renderPopUp(popUp)}
        <h2 className="category">Technology Stack</h2>
        <hr className="line" />
        <div className="technology_container">
          <div className="tech ruby">
            <div className="m-right-line" />
            <div className="m-bottom-line" />
            <div className="m-left-line" />
            <div className="top-line" />
            <div className="corner" />
            <button
              type="button"
              onClick={() => this.showPopUp({
                type: 'ruby',
                text: <span>
                  Ruby expertise,
                  <br />
                </span>,
                style: { width: '16.28%', margin: '.09rem 0 .74rem 0' },
              })}
              aria-label="Ruby"
            >
              <img
                className="image lazyload"
                alt="ruby"
                data-src={Ruby}
              />
            </button>
            <button
              className="image_mobile"
              type="button"
              onClick={() => this.showPopUp({
                type: 'ruby_mobile',
                text: <span>
                  Ruby expertise,
                  <br />
                </span>,
                style: { width: '23.4%', margin: '.72rem 0 1.25rem 0' },
              })}
            >
              <img
                className="lazyload"
                alt="ruby-mobile"
                data-src={RubyMobile}
              />
            </button>
            <h5 className="text">Ruby</h5>
          </div>
          <div className="container_category">
            <div className="tech react">
              <div className="m-left-line" />
              <div className="m-right-line" />
              <div className="m-bottom-line" />
              <div className="corner" />
              <div className="bottom-line" />
              <div className="right-line" />
              <button
                type="button"
                onClick={() => this.showPopUp({
                  type: 'react',
                  text: <span>
                    React.js
                    <br />
                    expertise,
                  </span>,
                  style: { width: '15.4%', margin: '.1rem 0 .75rem 0' },
                })}
                aria-label="React"
              >
                <img
                  className="image lazyload"
                  alt="react"
                  data-src={ReactIcon}
                />
              </button>
              <button
                className="image_mobile"
                type="button"
                onClick={() => this.showPopUp({
                  type: 'react_mobile',
                  text: <span>
                    React.js expertise,
                    <br />
                  </span>,
                  style: { width: '22.6%', margin: '.67rem 0 1.25rem 0' },
                })}
              >
                <img
                  className="lazyload"
                  alt="react-mobile"
                  data-src={ReactIconMobile}
                />
              </button>
              <h5 className="text">React.js</h5>
            </div>
            <div className="tech elixir">
              <div className="bottom-line" />
              <div className="m-bottom-line" />
              <div className="m-bottom-corner" />
              <div className="m-right-line" />
              <button
                type="button"
                onClick={() => this.showPopUp({
                  type: 'elixir',
                  text: <span>
                    Elixir expertise,
                    <br />
                  </span>,
                  style: { width: '9.4%', margin: '.17rem 0 .76rem 0' },
                })}
                aria-label="Elixir"
              >
                <img
                  className="image lazyload"
                  alt="elixir"
                  data-src={Elixir}
                />
              </button>
              <button
                className="image_mobile"
                type="button"
                onClick={() => this.showPopUp({
                  type: 'elixir_mobile',
                  text: <span>
                    Elixir expertise,
                    <br />
                  </span>,
                  style: { width: '14.2%', margin: '.67rem 0 1.3rem 0' },
                })}
              >
                <img
                  className="lazyload"
                  alt="elixir-mobile"
                  data-src={ElixirMobile}
                />
              </button>
              <h5 className="text">Elixir</h5>
            </div>
          </div>
          <div className="container-mobile">
            <div className="container_demicategory">
              <div className="tech qa">
                <div className="m-left-line" />
                <div className="m-bottom-corner" />
                <div className="corner" />
                <div className="top-line" />
                <button
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'qa',
                    text: <span>
                      QA expertise,
                      <br />
                    </span>,
                    style: { width: '13%', margin: '.17rem 0 .76rem 0' },
                  })}
                  aria-label="QA"
                >
                  <img className="image lazyload" alt="qa" data-src={QA} />
                </button>
                <button
                  type="button"
                  className="image_mobile"
                  onClick={() => this.showPopUp({
                    type: 'qa_mobile',
                    text: <span>
                      QA expertise,
                      <br />
                    </span>,
                    style: { width: '19.2%', margin: '.72rem 0 1.3rem 0' },
                  })}
                >
                  <img
                    className="lazyload"
                    alt="qa-mobile"
                    data-src={QAMobile}
                  />
                </button>
                <h5 className="text">QA</h5>
              </div>
              <div className="tech web_design">
                <div className="m-right-line" />
                <div className="m-bottom-line" />
                <div className="bottom-line" />
                <div className="right-line" />
                <div className="left-line" />
                <button
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'web_design',
                    text: <span>
                      Web Design
                      <br />
                      expertise,
                    </span>,
                    style: { width: '11.1%', margin: '.21rem 0 .76rem 0' },
                  })}
                  aria-label="Web Design"
                >
                  <img
                    className="image lazyload"
                    alt="web_design"
                    data-src={WebDesign}
                  />
                </button>
                <button
                  className="image_mobile"
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'web_design_mobile',
                    text: <span>
                      Web Design
                      <br />
                      expertise,
                    </span>,
                    style: { width: '24.4%', margin: '.67rem 0 1.25rem 0' },
                  })}
                >
                  <img
                    className="lazyload"
                    alt="web_design-mobile"
                    data-src={WebDesignMobile}
                  />
                </button>
                <h5 className="text">Web Design</h5>
              </div>
            </div>
            <div className="container_demicategory">
              <div className="tech devops">
                <div className="m-bottom-line" />
                <div className="top-line" />
                <div className="bottom-line" />
                <button
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'devops',
                    text: <span>
                      DevOps
                      <br />
                      expertise,
                    </span>,
                    style: { width: '16.5%', margin: '.15rem 0 .71rem 0' },
                  })}
                  aria-label="DevOps"
                >
                  <img
                    className="image lazyload"
                    alt="devops"
                    data-src={DevOps}
                  />
                </button>
                <button
                  className="image_mobile"
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'devops_mobile',
                    text: <span>
                      DevOps expertise,
                      <br />
                    </span>,
                    style: { width: '16.5%', margin: '.72rem 0 1.3rem 0' },
                  })}
                >
                  <img
                    className="lazyload"
                    alt="devops-mobile"
                    data-src={DevOpsMobile}
                  />
                </button>
                <h5 className="text">DevOps</h5>
              </div>
              <div className="tech mobile">
                <div className="m-bottom-corner" />
                <div className="bottom-line" />
                <button
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'mobile',
                    text: <span>
                      Mobile
                      <br />
                      development expertise,
                    </span>,
                    style: { width: '13.5%', margin: '.15rem 0 .52rem 0' },
                  })}
                  aria-label="Mobile"
                >
                  <img
                    className="image lazyload"
                    alt="mobile"
                    data-src={Mobile}
                  />
                </button>
                <button
                  className="image_mobile"
                  type="button"
                  onClick={() => this.showPopUp({
                    type: 'mobile_mobile',
                    text: <span>
                      Mobile
                      <br />
                      development expertise,
                    </span>,
                    style: { width: '20%', margin: '.67rem 0 1.3rem 0' },
                  })}
                >
                  <img
                    className="lazyload"
                    alt="mobile-mobile"
                    data-src={MobileMobile}
                  />
                </button>
                <h5 className="text">Mobile</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
