import React from 'react';

export default class FormFooter extends React.PureComponent {
  render() {
    return (
      <div className="form_footer">
        <div className="text">Or drop us a line at&nbsp;</div>
        <a className="email" href="mailto:info@cybergizer.com">
          info@cybergizer.com
        </a>
      </div>
    );
  }
}
