import React from 'react';
import ContactForm from './ContactForm';
import FormFooter from './FormFooter';
import ModalWindow from './ModalWindow';

export default class FormPopup extends React.PureComponent {
  render() {
    const { open, closeFormPopup } = this.props;

    return (
      <>
        {open
          && (
          <ModalWindow>
            <div className="form_popup_overlay" onClick={closeFormPopup}>
              <div
                className="form_popup_container"
                onClick={(e) => {
                  e.stopPropagation();
                  return false;
                }}
              >
                <button type="button" className="cross_btn" onClick={closeFormPopup} />
                <h3 className="form_popup_title">talk to us</h3>
                <ContactForm handleClose={closeFormPopup} />
                <FormFooter />
              </div>
            </div>
          </ModalWindow>
          )
        }
      </>
    );
  }
}
