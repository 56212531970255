import React from 'react';
import LeftSlideArrow from 'images/icons/left_slide_arrow.svg';

export const NextArrow = (props) => {
  const {
    currentSlide, slideCount, src, ...any
  } = props;
  return (
    <img
      src={src || '/assets/icons/right_slide_arrow.svg'}
      alt="right_arrow"
      {...any}
    />
  );
};
export const PrevArrow = (props) => {
  const {
    currentSlide, slideCount, src, ...any
  } = props;
  return (
    <img
      src={src || LeftSlideArrow}
      alt="left_arrow"
      {...any}
    />
  );
};
