import React from 'react';
import Slider from 'react-slick';
import Title from 'images/title.svg';
import { NextArrow, PrevArrow } from '../../layouts/SlickArrow';

export default class OurCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_version: false,
    };
  }

  componentDidMount() {
    if (window && window.screen.width < 1024) {
      this.setState({
        mobile_version: true,
      });
    }
  }

  renderSlide = (data) => {
    const customer = data;
    return (
      <div key={`${customer.name} ${customer.last_name}`}>
        <div className="slide">
          <div className="image_container">
            <img className="avatar" src={customer.avatar} alt="avatar" />
            <h2 className="name">{`${customer.name} ${customer.last_name}`}</h2>
            <img className="cybergizer" src={Title} alt="cybergizer_icon" />
            <div className="links">
              <a className="link linkedin" href={customer.linkedin} />
              <a className="link site" href={customer.site} />
            </div>
          </div>
          <div className="description">
            <p>{customer.description}</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { mobile_version } = this.state;
    const { customers } = this.props;
    let settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    if (mobile_version) {
      settings = Object.assign(settings, {
        dots: true,
        arrows: false,
      });
    } else {
      settings = Object.assign(settings, {
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      });
    }

    return (
      <div className="our_customers">
        <h2 className="category">Our customers</h2>
        <hr className="line" align="center" />
        <div className="container">
          <Slider {...settings} className="slider">
            {customers.map(slide => this.renderSlide(slide))}
          </Slider>
        </div>
      </div>
    );
  }
}
