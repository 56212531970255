import React from 'react';
import { createBrowserHistory } from 'history';
import Logo from 'images/cybergizer_symbol';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false, activelink: '' };
    this.links = {
      home: '/',
      about: 'about',
      services: 'services',
      team: '/team',
      events: 'events',
      'Contact us': 'contactus',
    };
    this.activeMenu = [];
    this.scrollMenu = this.scrollMenu.bind(this);
  }

  componentDidMount() {
    const location = window.location.pathname;
    const navSections = [];
    Object.values(this.links).forEach((link) => {
      if (!link.match(/\//)) navSections.push(link);
    });

    const activelink = Object.keys(this.links)
      .filter(link => this.links[link] === location)[0];
    this.setState({ activelink });

    this.domNavigations = navSections.map(id => document.getElementById(id));
    this.heightHeader = document.querySelector('.header').clientHeight;

    if (location === '/') {
      window.addEventListener('scroll', this.scrollMenu);
    }
  }

  scrollMenu = () => {
    const scroll = window.pageYOffset;

    if (this.activeMenu.length) {
      const top = this.activeMenu[0].offsetTop - this.heightHeader;
      const bottom = top + this.activeMenu[0].clientHeight - this.heightHeader;
      if (scroll >= top && scroll < bottom) return null;
    }

    this.activeMenu = this.domNavigations.filter((domEl) => {
      const top = domEl.offsetTop - this.heightHeader;
      const bottom = top + domEl.clientHeight - this.heightHeader;
      if (scroll >= top && scroll < bottom) return true;
      return false;
    });

    if (this.activeMenu.length) {
      const id = this.activeMenu[0].getAttribute('id');
      const activelink = Object.keys(this.links)
        .filter(link => this.links[link] === id)[0];
      this.setState({ activelink });
    } else if (scroll > 0 && scroll < this.heightHeader) {
      const homeMenu = document.querySelector('.nav-link[href="/"]');

      if (!homeMenu.classList.contains('active')) {
        this.setState({ activelink: 'home' });
      }
    }
    return null;
  }

  handleOnClick = () => {
    const { active } = this.state;
    this.setState({ active: !active });
  }

  scrollTo = (event) => {
    const id = event.target.getAttribute('href');
    const heigth = document.querySelector('.header').offsetHeight;
    const elem = document.getElementById(id);
    const scrollId = id === '/' ? '/' : `/#${id}`;
    if (!id.match(/\//) || id === '/') {
      event.preventDefault();
      if (document.location.pathname === '/') {
        const top = elem ? elem.offsetTop - heigth : 0;
        window.scrollTo({
          top,
          behavior: 'smooth',
        });
        createBrowserHistory().replace(scrollId, this.state);
        this.setState({ active: false });
      } else {
        window.open(scrollId, '_self', 'location=yes');
      }
    } else window.removeEventListener('scroll', this.scrollMenu);
  }


  render() {
    const { active, activelink } = this.state;
    const links = Object.keys(this.links);
    const { redirect } = this.props;
    return (
      <div className="header">
        <a className="symbol" href="/">
          <img
            className="symbol_cybergizer"
            src={Logo}
            alt="symbol_cybergizer"
          />
        </a>
        <nav className={`navigation ${active ? 'open' : ''}`}>
          <ul className="links">
            {links.map(link => (
              <li key={link} className="link">
                <a
                  className={`nav-link ${link === activelink ? 'active' : ''}`}
                  href={redirect ? `/#${this.links[link]}` : this.links[link]}
                  onClick={this.scrollTo}
                >
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <button
          type="button"
          href="contactus"
          className="talk_to_us"
          onClick={this.scrollTo}
        >
                Talk to us
        </button>
        <button
          type="button"
          className={`menu-button ${active ? 'close' : ''}`}
          onClick={this.handleOnClick}
          aria-label="Menu"
        >
          <span className="line1" />
          <span className="line2" />
          <span className="line3" />
        </button>
      </div>
    );
  }
}
