import React from 'react';
import { createBrowserHistory } from 'history';
import CardCircles from 'images/card-circles.svg';
import RedCardCircles from 'images/red-card-circles.svg';

export default class ServicesCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  scrollToForm = (event) => {
    event.stopPropagation();
    const heigth = document.querySelector('.header').offsetHeight;
    const elem = document.getElementById('contactus');
    const top = elem ? elem.offsetTop - heigth : 0;
    if (window) {
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
    createBrowserHistory().replace('/#contactus', this.state);
  }

  rotate = (event) => {
    event.stopPropagation();
    if (event.target.classList.contains('open_button')
      || event.target.parentNode.classList.contains('open_button')) {
      event.currentTarget.classList.toggle('rotate');
    } else {
      const isRotate = event.nativeEvent.composedPath()
        .some(el => (el.classList ? el.classList.contains('back_card') : false));
      if (isRotate) event.currentTarget.classList.toggle('rotate');
    }
  }

  renderCard = () => (
    <>
      <div className="card" onClick={this.rotate}>
        <div className="front_card">
          <div className="front_container">
            <div className="top-corner" />
            <div className="bottom-corner" />
            <div className="triangle-base" />
            <div className="top-side" />
            <div className="bottom-side" />
            <img
              src={CardCircles}
              alt="card-circles"
              className="circles-1"
            />
            <div className="pretitle">
              Need
              <br />
              engineering
              <br />
              power?
              <div className="open_button">
                <div className="circle">
                  <span className="icon" />
                </div>
                <p className="button_text">We got one</p>
              </div>
            </div>
          </div>
        </div>
        <div className="back_card">
          <div className="back_container">
            <div className="top-side-black" />
            <div className="bottom-side-black" />
            <div className="title">DEDICATED TEAMS</div>
            <div className="text">
              <p>
                Our developers are smart - like Newton, Einstein, or Tesla - there’s no
                &nbsp;problem they can’t solve.
              </p>
              <p>
                Our customers trust us to extend their development teams making the
                &nbsp;perfect match with the core team or act as an independent unit that
                &nbsp;delivers right solutions in right time.
              </p>
            </div>
            <div className="footer-button first-footer red" onClick={this.scrollToForm}>
              We can help
              <span className="arrow" />
            </div>
          </div>
        </div>
      </div>
      <div className="card" onClick={this.rotate}>
        <div className="front_card">
          <div className="front_container">
            <div className="top-corners-2" />
            <div className="bottom-corners-2" />
            <div className="top-black-side-2" />
            <div className="bottom-black-side-2" />
            <img
              data-src={RedCardCircles}
              alt="red-card-circles"
              className="circles-2 lazyload"
            />
            <div className="pretitle">
              Need
              <br />
              to kick-start
              <br />
              a product?
              <div className="open_button">
                <div className="circle">
                  <span className="icon" />
                </div>
                <p className="button_text">We got the key</p>
              </div>
            </div>
          </div>
        </div>
        <div className="back_card">
          <div className="back_container">
            <div className="top-side-red" />
            <div className="bottom-side-red" />
            <div className="title">PRODUCT ENGINEERING</div>
            <div className="text">
              <p>
                We know what it takes to bring a product from concept into business-ready
                &nbsp;solution.
              </p>
              <p>We think lean.</p>
              <p>
                We’ll help you refine the big ideas into the best launch strategy:
                &nbsp;from MVP to solutions at scale, and engineer them.
              </p>
            </div>
            <div
              className="footer-button second-footer black"
              onClick={this.scrollToForm}
            >
              Drop us a line
              <span className="arrow" />
            </div>
          </div>
        </div>
      </div>
      <div className="card" onClick={this.rotate}>
        <div className="front_card">
          <div className="front_container">
            <div className="top-left-corner-3" />
            <div className="bottom-right-corner-3" />
            <div className="top-right-corner-3" />
            <div className="bottom-left-corner-3" />
            <img
              data-src={CardCircles}
              alt="card-circles"
              className="circles-3 lazyload"
            />
            <div className="pretitle">
              Need
              <br />
              to skyrocket
              <br />
              team’s
              <br />
              productivity?
              <div className="open_button">
                <div className="circle">
                  <span className="icon" />
                </div>
                <p className="button_text">We got the fuel</p>
              </div>
            </div>
          </div>
        </div>
        <div className="back_card">
          <div className="back_container">
            <div className="top-side-black" />
            <div className="bottom-side-black" />
            <div className="title">TEAM MENTORSHIP</div>
            <div className="text">
              <p>Engineering excellence is in our blood.</p>
              <p>
                We help teams to adopt best engineering practices, processes, and tools.
              </p>
              <p>
                We form the engineering culture and product development mindset to
                &nbsp;facilitate faster delivery of better quality.
              </p>
            </div>
            <div className="footer-button third-footer red" onClick={this.scrollToForm}>
              Just let us know
              <span className="arrow" />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  render() {
    return (
      <div className="services" id="services">
        <div className="category">Services</div>
        <hr className="line" align="center" />
        <div className="cards">
          {this.renderCard()}
        </div>
      </div>
    );
  }
}
