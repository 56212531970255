import React from 'react';

export default class Recaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  grecaptcha = null;

  componentDidMount() {
    if (!document.getElementById('recatpcha_script')) {
      const { captchaSiteKey, action } = this.props;
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${captchaSiteKey}`;
      script.async = true;
      script.id = 'recatpcha_script';
      script.addEventListener('load', () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(captchaSiteKey, { action }).then((token) => {
            this.setState({ value: token });
          });
        });
      });

      document.body.appendChild(script);
    }
  }

  render() {
    const { action, recaptchaInputRef } = this.props;
    const { value } = this.state;
    return (
      <input
        type="hidden"
        id="recaptcha_input"
        name="recaptcha_token"
        action={action}
        value={value}
        ref={recaptchaInputRef}
      />
    );
  }
}
