import React from 'react';
import Slider from 'react-slick';
import { NextArrow, PrevArrow } from '../../layouts/SlickArrow';

export default class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mobile_version: false };
  }

  componentDidMount() {
    if (typeof (window) === 'undefined' && window.screen.width < 1024) {
      this.setState({ mobile_version: true });
    }
  }

  renderSlide = data => (
    <div className="slide" key={`slide_key_${data.title}`}>
      <div className="image">
        <img
          className="project_image"
          src={data.image}
          alt="project_image"
        />
      </div>
      <div className="content">
        <h2 className="project_title">{data.name}</h2>
        <p className="text">
          {data.description}
        </p>
        {this.renderTable(data)}
        <button className="button" type="button">Learn more</button>
      </div>
    </div>
  )

  renderTable = (data) => {
    const { mobile_version } = this.state;
    if (mobile_version) {
      return (
        <div className="table">
          <div className="thead">
            <div className="col">
              <div className="head">Technology</div>
              <div className="body">{data.technology}</div>
            </div>

            <div className="col">
              <div className="head">Team</div>
              <div className="body">{data.team}</div>
            </div>

            <div className="col">
              <div className="head">Industry</div>
              <div className="body">{data.industry}</div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="table">
        <div className="thead">
          <div className="col">
            <div className="head">Technology</div>
            <div className="body">{data.technology}</div>
          </div>

          <div className="col">
            <div className="head">Industry</div>
            <div className="body">{data.industry}</div>
          </div>

          <div className="col">
            <div className="head">Team</div>
            <div className="body">{data.team}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { mobile_version } = this.state;
    const { projects } = this.props;
    let settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    if (mobile_version) {
      settings = Object.assign(settings, {
        dots: true,
        arrows: false,
      });
    } else {
      settings = Object.assign(settings, {
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      });
    }

    return (
      <div className="projects" id="projects">
        <h2 className="category">Projects</h2>
        <hr className="line" align="center" />
        <div className="container">
          <Slider {...settings} className="slider">
            {projects.map(slide => this.renderSlide(slide))}
          </Slider>
        </div>
      </div>
    );
  }
}
