import React from 'react';
import ContactForm from '../../layouts/ContactForm';
import FormFooter from '../../layouts/FormFooter';


export default class MainBottomForm extends React.PureComponent {
  render() {
    const { csrf_token } = this.props;
    return (
      <div className="main_bottom_form" id="contactus">
        <h2 className="category">Talk to us</h2>
        <hr align="center" className="line" />
        <ContactForm
          csrf_token={csrf_token}
          type="main_bottom_form"
        />
        <FormFooter />
      </div>
    );
  }
}
