import React from 'react';

const EMAIL_REGEXP = /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/;

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      error: {
        name: false,
        email: false,
        message: false,
      },
      formValid: false,
      checked: false
    };
  }

  handleChange = ({ target }) => {
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    },
    () => this.validateField(name, value));
  }

  changeCheckbox = () => {
    this.setState((state) => {
      return {
        checked: !state.checked
      };
    });
  };

  placeholder = (nameField) => {
    const message = 'How can we help?';

    if (nameField === 'message') return message;
    return `Your ${nameField}`;
  }

  validateField = (nameField, value) => {
    const {
      name, message, email, error,
    } = this.state;
    const { disable } = this.props;
    const fields = {
      name,
      message,
      email,
    };
    if (disable) {
      delete fields[disable];
      delete error[disable];
    }

    const msgError = this.isValid(nameField, value);
    error[nameField] = msgError;
    const isValid = Object.values(fields).every(val => val !== '')
                    && Object.values(error).every(msgErr => !msgErr);
    this.setState({
      error,
      formValid: isValid,
    });
  }

  isValid = (name, value) => {
    if (value === '') return 'Required';

    switch (name) {
      case 'name': return value.length > 1
        ? false : 'Name must be at least 2 character';
      case 'message': return value.length > 8
        ? false : 'Message must be at least 9 characters';
      case 'email': return value.match(EMAIL_REGEXP)
        ? false : 'Please enter a valid email';
      default: return false;
    }
  }

  handleSubmit = (event) => {
    const {
      name, message, email, formValid,
    } = this.state;
    const fields = {
      name,
      message,
      email,
    };
    if (!formValid) {
      event.preventDefault();
      Object.entries(fields)
        .forEach(field => this.validateField(field[0], field[1]));
    }
  }

  render() {
    const {
      name, email, message, error, checked
    } = this.state;
    const {
      children, csrf_token, type, handleClose, disable, nameSendBtn,
    } = this.props;
    return (
      <form
        className="contact_form"
        action="/contacts"
        method="post"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="type" value={type} />
        <input type="hidden" name="authenticity_token" value={csrf_token} />
        <div className="wrap_input no_margin">
          <textarea
            className={`input message ${error.message ? 'error' : ''}`}
            name="message"
            placeholder={this.placeholder('message')}
            value={message}
            onChange={this.handleChange}
            type="text"
            aria-label="Message"
            disabled={disable === 'message' ? 'disabled' : ''}
          />
          {error.message
            && <span className="form_error">{`* ${error.message}`}</span>}
        </div>
        <div className="wrap_input">
          <input
            className={`input ${error.name ? 'error' : ''}`}
            type="text"
            name="name"
            placeholder={this.placeholder('name')}
            value={name}
            onChange={this.handleChange}
            aria-label="Name"
          />
          {error.name
            && <span className="form_error">{`* ${error.name}`}</span>}
        </div>
        <div className="wrap_input">
          <input
            className={`input ${error.email ? 'error' : ''}`}
            type="text"
            name="email"
            placeholder={this.placeholder('email')}
            value={email}
            onChange={this.handleChange}
            aria-label="Email"
          />
          {error.email
            && <span className="form_error">{`* ${error.email}`}</span>}
        </div>
        <div className="wrap_info">
        <input type="checkbox" className="wrap_label" id="buttonAgree" name="buttonAgree" checked={checked} onChange={this.changeCheckbox} />
        <label className="wrap_checkbox" htmlFor="buttonAgree"> I agree with the<a className="link" href="/privacy_policy">&nbsp;privacy policy</a></label> 
        </div>
        <input
          name="recaptcha_token"
          className="recaptcha_input"
          type="hidden"
        />
        {children
          && (
          <div className="form_children">
            {children}
          </div>
          )
        }
        <div className="form_buttons">
          <button
            type="submit"
            className="form_btn send"
            id="contacts_form_submit"
            description={'IT services company specialising on Ruby, Rails, Elixir,'
                  + ' React. Dedicated teams and engineers, product development,'
                  + ' consulting, engineering excellence'}
                  disabled={!checked}
          >
            {nameSendBtn || 'Send'}
          </button>
          <button
            type="button"
            className="form_btn close"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }
}
