import React from 'react';

export default class Recaptcha extends React.Component {
  componentDidMount() {
    if (window) {
      window.grecaptcha = null;
      const { recaptcha_site_key } = this.props;
      if (!document.getElementById('recapcha_script')) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptcha_site_key}`;
        script.async = true;
        script.id = 'recapcha_script';
        script.addEventListener('load', () => {
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptcha_site_key, { action: 'form' })
              .then((token) => {
                Array.from(document.body.getElementsByClassName('recaptcha_input'))
                  .forEach(input => input.setAttribute('value', token));
                Array.from(document.body.getElementsByClassName('form_btn send'))
                  .forEach(input => input.removeAttribute('disabled'));
              });
          });
        });
        document.body.appendChild(script);
      }
    }
  }

  render() {
    return null;
  }
}
