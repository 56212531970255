import React from 'react';
import axios from 'axios';

export default class CookiesDisclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.disclaimerRef = React.createRef();
    this.state = { cookies: true, 
      buttonNecessary: true,
      buttonPreferences: true,
      buttonStatistics: true,
      buttonMarketing: true
      };
  }

  componentDidMount() {
    axios.get('/cookies').then(response => this.setState({ cookies: response.data }));
  }

  clickHandle = () => {
    const { csrf_token } = this.props;
    const { buttonNecessary, buttonPreferences, buttonStatistics, buttonMarketing} = this.state
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token;
    axios.post('/cookies', {
      necessary: buttonNecessary,
      preferences: buttonPreferences,
      statistics: buttonStatistics,
      marketing: buttonMarketing      
    }).then(() => {
      this.disclaimerRef.current.style.opacity = 0;
      setTimeout(() => this.setState({ cookies: true }), 1000);
    });
  }

  changeCheckbox = (event) => {
    const { name } = event.target;
    this.setState((state) => {
      return {
        [name]: !state[name]
      };
    });
  };

  render() {
    const { buttonNecessary, buttonPreferences, buttonStatistics, buttonMarketing, cookies } = this.state;
    return (
      <>
        {
        !cookies && (
          <div
            className="cookies_disclaimer"
            ref={this.disclaimerRef}
          >
            <p className="text">
              We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share<br /> information about your use of our site with our social media, advertising and analytics partners who may combine it with<br />  other information that you've provided to them or that they've collected from your use of their services. You consent to<br /> our cookies if you continue to use this website.
            </p>
            <p className="mobile_text">
              We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services. You consent to our cookies if you continue to use this website.
            </p>
            <div class="buttonSelectPane">
              <div className="wrap_info">
                <input type="checkbox" id="buttonNecessary" className="wrap_input" name="buttonNecessary" disabled="disabled" checked={buttonNecessary}/>
                <label className="wrap_checkbox" htmlFor="buttonNecessary">Necessary</label>
              </div>
              <div className="wrap_info">
                <input type="checkbox" id="buttonPreferences" className="wrap_input" name="buttonPreferences" checked={buttonPreferences} onChange={this.changeCheckbox}/>
                <label className="wrap_checkbox" htmlFor="buttonPreferences">Preferences</label>
              </div>
              <div className="wrap_info">
                <input type="checkbox" id="buttonStatistics" className="wrap_input" name="buttonStatistics" checked={buttonStatistics} onChange={this.changeCheckbox}/>
                <label className="wrap_checkbox" htmlFor="buttonStatistics">Statistics</label>
              </div>
              <div className="wrap_info">
                <input type="checkbox" id="buttonMarketing" className="wrap_input" name="buttonMarketing" checked={buttonMarketing} onChange={this.changeCheckbox}/>
                <label className="wrap_checkbox" htmlFor="buttonMarketing">Marketing</label>
              </div>
            </div>
            <button
              type="button"
              className="button"
              onClick={this.clickHandle}
              aria-label="Disclaimer Got It"
            >
              got it!
            </button>
          </div>
        )
      }
      </>
    );
  }
}
